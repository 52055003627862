<template>
    <b-overlay
      :show="showOverlay"
      rounded="sm"
    >
        <div class="claim">
            <h1 class="mb-1">Approved Claims</h1>
            
            <!-- btn form repeater office -->
            <b-card>
                <b-row>
                    <b-col
                        lg="12"
                        md="12"
                        sm="12"
                    >
                        <div class="mx-1">
                            <h4 style="font-size: 18px;" class="mb-0 pb-0 mt-1">Filters</h4>
                            <b-row class="mt-1 d-flex align-items-center justify-content-start mb-1 mb-md-0" cols="1" cols-sm="12" cols-md="4" cols-lg="4">
                                <b-col class="mb-25">
                                    <v-select
                                        v-model="clinicianName"
                                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                        placeholder="Clinician Name"
                                        label="full"
                                        :dense="true"
                                        :options="optionsClinicianName"
                                    />
                                </b-col>
                                <b-col class="mb-25">
                                    <b-form-select
                                        v-model="clinicianRegion"
                                        inline
                                        :options="optionsClinicianRegion"
                                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                        class="d-inline-block mr-50"
                                    />
                                </b-col>
                                <b-col class="mb-25" v-if="clinicianRegion === 'NYC'">
                                    <b-form-select
                                        v-model="clinicianBorough"
                                        inline
                                        :options="optionsBorough"
                                        placeholder="Borough"
                                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                        class="d-inline-block mr-50"
                                    />
                                </b-col>
                                <b-col class="mb-25">
                                    <b-form-select
                                        v-model="clinicianProgram"
                                        inline
                                        :options="optionsProgram"
                                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                        class="d-inline-block mr-50"
                                    />
                                </b-col>
                            </b-row>

                            <b-row class="my-1">
                                <b-col
                                    cols="12"
                                    md="6"
                                >
                                    <b-spinner class="align-middle text-primary" v-if="loadCounterAll"></b-spinner>  <span style="font-size: 14px; color: #000;" v-if="loadCounterAll"> results found</span>
                                    <h2 v-else>{{ arrayFiltradoClaimsApproved.length.toLocaleString('en-US') }} <span style="font-size: 14px; color: #000;"> results found</span></h2>
                                </b-col>

                                <b-col
                                    cols="12"
                                    md="6"
                                >
                                    <div
                                        class="mr-0 btn btn-default"
                                        :style="!loadCounterAll ? 'background: rgb(148, 34, 30) none repeat scroll 0% 0%; color: rgb(255, 255, 255); cursor: pointer; opacity: 1; float: right;' : 'background: rgb(148, 34, 30) none repeat scroll 0% 0%; color: rgb(255, 255, 255); cursor: no-drop; opacity: 0.5; float: right;'"
                                        @click="ckeckExporter()"
                                        v-if="(dataSpecialist || adminOrManageUsers) && loadCounterAll"
                                    >
                                        CSV Export
                                        <b-spinner small v-if="loadCounterAll" class="ml-50" style="padding-bottom: 0px;" />
                                    </div>

                                    <div
                                        class="mr-0 btn btn-default"
                                        :style="!loadCounterAll ? 'background: rgb(148, 34, 30) none repeat scroll 0% 0%; color: rgb(255, 255, 255); cursor: pointer; opacity: 1; float: right;' : 'background: rgb(148, 34, 30) none repeat scroll 0% 0%; color: rgb(255, 255, 255); cursor: no-drop; opacity: 0.5; float: right;'"
                                        @click="ckeckExporter()"
                                        v-else-if="(dataSpecialist || adminOrManageUsers)"
                                    >
                                        CSV Export
                                        <b-spinner small v-if="loadCounterAll" class="ml-50" style="padding-bottom: 3px;" />
                                    </div>
                                </b-col>
                            </b-row>
                        </div>

                        <b-table
                            :items="partialsData"
                            :fields="fieldsTableClinicianResume"
                            responsive
                            :busy="isBusyResumeApproved"
                        >
                            <!-- <template #table-busy>
                                <div class="text-center text-primary my-2">
                                    <b-spinner class="align-middle"></b-spinner>
                                    <strong class="ml-1">Loading...</strong>
                                </div>
                            </template> -->
                            <template #cell(actions)="data">
                                <b-button
                                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                                    variant="outline-secondary"
                                    style="padding: 6px 8px; font-size: 12px;"
                                    @click="processResumeClinician(data.item.clinicianID)"
                                    v-if="parseInt(data.item.noClaims, 10) >= 1 && isAdmin"
                                >
                                Process
                                </b-button>
                                <span v-else-if="!isAdmin">-</span>
                            </template>
                        </b-table>

                        <div class="text-center text-primary my-2" v-if="loadCounterAll">
                            <b-spinner class="align-middle"></b-spinner>
                            <strong class="ml-1">Loading...</strong>
                        </div>
                        <div class="text-center text-primary my-2" v-if="(arrayFiltradoClaimsApproved.length - partialsData.length >= 1) && !loadCounterAll">
                            <b-button
                                variant="outline-primary"
                                class="mt-1"
                                @click="loadDataPagination"
                            >
                            Load more
                            </b-button>
                        </div>
                    </b-col>
                </b-row>
            </b-card>
        </div>
    </b-overlay>
</template>

<script>
import { 
    BForm, BButton, BRow, BCol, BFormGroup, BFormInput, BFormTextarea, BTable, BFormInvalidFeedback, BCard,
    BFormSelect, VBTooltip, BPagination, BOverlay, BSpinner,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import EditClientClaim from '../user/users-edit/components/EditClientClaim.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import flatPickr from 'vue-flatpickr-component'
import { required, email } from '@validations'
import Cleave from 'vue-cleave-component'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import axios from 'axios'
import moment from 'moment'
import { mapActions, mapGetters, mapState } from 'vuex'
import { db, auth } from '@/firebase'

export default {
    data() {
        return {
            // view edit
            statusEdit: false,
            idClientClaim: '',
            adminOrManageUsers: false,
            isAdmin: false,
            dataSpecialist: false,
            clinicianOrClinicianViewer: false,
            isClinician: false,
            userUID: '',
            clinicianProgram: null,
            optionsProgram: [],
            json_data: [],

            // settings table and edit view
            itemsClaims: [],
            itemsClaimsQuery: [],
            fieldsTable: [
                { key: 'clientDetails', sortable: false },
                { key: 'clinicianDetails', sortable: false },
                { key: 'approvalStatus', sortable: false },
                { key: 'claimDate', sortable: false },
                { key: 'actions', label: '', sortable: false, thClass: 'thClaimsActions', tdClass: 'tdClaimsActions' },
            ],
            totalRows: 0,
            currentPage: 1,
            perPage: 10,
            pageOptions: [3, 5, 10, 25, 50, 100, 200],
            sortBy: 'claimDateFormat2',
            sortDesc: true,

            // filter
            clinicianName: null,
            optionsClinicianName: [],
            clientName: null,
            optionsClientName: [],
            clinicianRegion: null,
            optionsClinicianRegion: [],
            clinicianBorough: null,
            optionsBorough: [
                {value: null, text: 'Any Borough'},
                {value: 'Bronx', text: 'Bronx'},
                {value: 'Brooklyn', text: 'Brooklyn'},
                {value: 'Manhattan', text: 'Manhattan'},
                {value: 'Queens', text: 'Queens'},
                {value: 'Staten Island', text: 'Staten Island'},
            ],
            claimStatus: null,
            optionsClaimStatus: [
                {value: null, text: 'Any claim status'},
                {value: 'paid', text: 'Paid'},
                {value: 'approved', text: 'Approved'},
                {value: 'denied', text: 'Denied'},
                {value: 'pending dm approval', text: 'Pending DM Approval'},
                {value: 'pending pm approval', text: 'Pending PM Approval'},
            ],
            clinicianStatus: null,
            optionsClinicianStatus: [
                {value: null, text: 'Any status'},
                {value: 'active', text: 'Active Clinicians'},
                {value: 'inactive', text: 'Inactive Clinicians'},
            ],
            startDate: null,
            endDate: null,
            clientID: null,
            fixedClientID: null,

            // table clinician resume
            cliniciansResume: [],
            fieldsTableClinicianResume: [
                { key: 'clinician', sortable: false, tdClass: 'tdClinicianResumeName' },
                { key: 'noClaims', label: '# Claims', sortable: false, thClass: 'thClinicianNoClaims', tdClass: 'tdClinicianNoClaims' },
                { key: 'total', sortable: false, thClass: 'thClaimsApprovedTotal', tdClass: 'tdClaimsApprovedTotal'  },
                { key: 'actions', label: '', sortable: false, tdClass: 'tdClaimsApprovedActions' },
            ],

            // overlay
            variant: 'light',
            opacity: 0.85,
            blur: '2px',
            isBusy: true,
            latestDoc: null,
            latestDocQuery: null,
            latestDocApprv: null,
            latestDocClient: null,
            latestDocClinician: null,
            loadingInfiniteScroll: false,
            apiLoaded: true,
            activeFilters: false,
            activeFiltersDate: false,
            isBusyResumeApproved: true,
            isCompleteData: false,
            showOverlay: false,

            // new vars query claims
            totalRowsQuery: 0,
            loadCounterQuery: false,
            loadCounterAll: true,
            clientUID: '',
            partialsData: []
        }
    },
    components: {
        BForm,
        BButton,
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        BFormTextarea,
        BTable,
        BFormInvalidFeedback,
        BCard,
        BFormSelect,
        BPagination,
        BOverlay,
        BSpinner,
        Cleave,
        
        // select
        vSelect,

        // date
        flatPickr,

        // Form Validation
        ValidationProvider,
        ValidationObserver,

        // edit claim
        EditClientClaim,
    },
    directives: {
        Ripple,
        'b-tooltip': VBTooltip,
    },
    computed: {
        ...mapGetters(['arrayFiltradoClaimsApproved']),
        ...mapState(['searchClaimApprovedClinicianNameState','searchClaimApprovedClinicianRegionState','searchClaimApprovedClinicianProgramState'])
    },
    watch: {
        clinicianName(val) {
            this.perPage = 10
            val ? this.perPage += 20 : this.perPage = 10
            this.apiLoaded = true
            this.loadCounterQuery = true
            this.latestDocQuery = null
            if(val) {
                if(val.value === 'All Clinicians') {
                    this.clinicianName = null
                    this.apiLoaded = false
                    this.loadCounterQuery = false
                    this.checkActiveFilters()
                } else {
                    this.checkActiveFilters()
                    this.searchClaimApprovedClinicianName(val)
                    val.value ? this.searchClaimApprovedClinicianName(val) : this.searchClaimApprovedClinicianName(val)
                }
            } else {
                this.apiLoaded = false
                this.loadCounterQuery = false
                this.checkActiveFilters()
                this.searchClaimApprovedClinicianName('')
            }
            // this.searchClaimApprovedClinicianName(val)
        },
        // clientName(val) {
        //     this.perPage = 10
        //     val ? this.perPage += 20 : this.perPage = 10
        //     this.apiLoaded = true
        //     this.loadCounterQuery = true
        //     this.latestDocQuery = null
        //     // if(val) {
        //     //     this.activeFilters = true
        //     //     this.searchClaimClientName(val.value)
        //     // } else {
        //     //     this.searchClaimClientName('')
        //     // }
        //     // console.log(val)
        //     this.clientUID = ''
        //     if(val) {
        //         if(val.value === 'All Clients') {
        //             this.clientName = null
        //             this.apiLoaded = true
        //             this.loadCounterQuery = false
        //             this.clientID = null
        //             this.checkActiveFilters()
        //         } else {
        //             this.clientUID = val.uid ? val.uid : ''
        //             this.checkActiveFilters()
        //             // this.clientID = val.codigo.includes('#') ? val.codigo : '#'+val.codigo
        //             this.searchClaimClientName(val.value)
        //         }
        //     } else {
        //         this.apiLoaded = false
        //         this.loadCounterQuery = false
        //         this.clientID = null
        //         this.searchClaimClientName('')
        //         this.checkActiveFilters()
        //     }
        //     // this.searchClaimClientName(val)
        // },
        // claimStatus(val) {
        //     this.perPage = 10
        //     val ? this.perPage += 20 : this.perPage = 10
        //     this.apiLoaded = true
        //     this.loadCounterQuery = true
        //     this.latestDocQuery = null
        //     this.checkActiveFilters()
        //     this.searchClaimClaimStatus(val)
        // },
        clinicianRegion(val) {
            this.perPage = 10
            val ? this.perPage += 20 : this.perPage = 10
            this.apiLoaded = true
            this.loadCounterQuery = true
            this.latestDocQuery = null
            this.checkActiveFilters()
            this.clinicianBorough = this.clinicianRegion === 'NYC' && this.clinicianBorough ? this.clinicianBorough : null
            this.searchClaimApprovedClinicianRegion(val)
        },
        clinicianBorough(val) {
            this.perPage = 10
            val ? this.perPage += 20 : this.perPage = 10
            this.apiLoaded = true
            this.loadCounterQuery = true
            this.latestDocQuery = null
            this.checkActiveFilters()
            // console.log(val)
            if(val) {
                this.searchClaimApprovedClinicianRegion(val)
            } else {
                if(this.clinicianRegion) {
                    this.searchClaimApprovedClinicianRegion(this.clinicianRegion)
                } else {
                    this.searchClaimApprovedClinicianRegion(val)
                }
            }
        },
        // clinicianStatus(val) {
        //     this.perPage = 10
        //     val ? this.perPage += 20 : this.perPage = 10
        //     this.apiLoaded = true
        //     this.loadCounterQuery = true
        //     this.latestDocQuery = null
        //     this.checkActiveFilters()
        //     this.searchClaimClinicianStatus(val)
        // },
        // startDate(val) {
        //     this.perPage = 10
        //     val ? this.perPage += 20 : this.perPage = 10
        //     this.apiLoaded = true
        //     this.loadCounterQuery = true
        //     this.latestDocQuery = null
        //     this.checkActiveFilters()
        //     this.searchClaimStartDate(val)
        // },
        // endDate(val) {
        //     this.perPage = 10
        //     val ? this.perPage += 20 : this.perPage = 10
        //     this.apiLoaded = true
        //     this.loadCounterQuery = true
        //     this.latestDocQuery = null
        //     this.checkActiveFilters()
        //     this.searchClaimEndDate(val)
        // },
        // clientID(val) {
        //     // var valFixed = val ? val.includes('#') ? val.replace(/^(0+)/g, '') : '#'+val.replace(/^(0+)/g, '') : ''
        //     var valFixed = val ? val.includes('#') ? val : '#'+val : ''
        //     if(this.fixedClientID !== valFixed) {
        //         // this.fixedClientID = val ? val.includes('#') ? val.replace(/^(0+)/g, '') : '#'+val.replace(/^(0+)/g, '') : ''
        //         this.fixedClientID = val ? val.includes('#') ? val : '#'+val : ''
        //         this.perPage = 10
        //         val ? this.perPage += 20 : this.perPage = 10
        //         this.apiLoaded = true
        //         this.loadCounterQuery = true
        //         this.latestDocQuery = null
        //         this.checkActiveFilters()
        //         // console.log(val.replace(/^(0+)/g, ''))
        //         val ? this.searchClaimsClientID(val.includes('#') ? val : '#'+val) : this.searchClaimsClientID('')
        //     }
        // },
        clinicianProgram(val) {
            this.perPage = 10
            val ? this.perPage += 20 : this.perPage = 10
            this.apiLoaded = true
            this.loadCounterQuery = true
            this.latestDocQuery = null
            this.checkActiveFilters()
            this.searchClaimApprovedClinicianProgram(val)
        },
        activeFilters(val) {
            // console.log(val)
            if(val) {
                this.perPage += 30
            } else {
                this.perPage = 10
            }
            // console.log(this.perPage)
        },
        perPage(val) {
            if(this.activeFilters) this.loadingInfiniteScroll = false
        },
        arrayFiltradoClaimsApproved(val) {
            if(this.isCompleteData) {
                this.apiLoaded = false
            }
            // this.totalRows = val.length
            this.partialsData = []

            this.loadDataPagination()
        }
    },
    methods: {
        ...mapActions(['searchClaimApprovedClinicianName','searchClaimApprovedClinicianRegion','searchClaimApprovedClinicianProgram','setSnapshotClaimsApproved','getClaims']),
        ckeckExporter() {
            this.$swal({
                title: 'Export type',
                // text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Current Page',
                cancelButtonText: 'All results matching your search',
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-primary ml-1',
                },
                buttonsStyling: false,
            }).then(result => {
                if (result.value) {
                    // this.$swal({
                    //     icon: 'success',
                    //     title: 'Deleted!',
                    //     text: 'Your file has been deleted.',
                    //     customClass: {
                    //     confirmButton: 'btn btn-success',
                    //     },
                    // })

                    let csv = 'clinician;noClaims;total\n';
                    this.partialsData.forEach((row) => {
                        // console.log(row)
                        var arr = [row.clinician,row.noClaims,row.total]
                        csv += arr.join(';')
                        // csv += row.clinician.join(',')
                        // csv += row.noClaims.join(',')
                        // csv += row.total.join(',')
                        csv += "\n"
                    })
                
                    const anchor = document.createElement('a');
                    anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
                    anchor.target = '_blank';
                    anchor.download = 'approved-claims.csv';
                    anchor.click();
                } else if (result.dismiss === 'cancel') {
                    // this.$swal({
                    //     title: 'Cancelled',
                    //     text: 'Your imaginary file is safe :)',
                    //     icon: 'error',
                    //     customClass: {
                    //     confirmButton: 'btn btn-success',
                    //     },
                    // })

                    let csv = 'clinician;noClaims;total\n';
                    this.arrayFiltradoClaimsApproved.forEach((row) => {
                        // console.log(row)
                        var arr = [row.clinician,row.noClaims,row.total]
                        csv += arr.join(';')
                        // csv += row.clinician.join(',')
                        // csv += row.noClaims.join(',')
                        // csv += row.total.join(',')
                        csv += "\n"
                    })
                
                    const anchor = document.createElement('a');
                    anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
                    anchor.target = '_blank';
                    anchor.download = 'approved-claims.csv';
                    anchor.click();
                }
            })
            // this.json_data = []
        },
        loadDataPagination() {
            // this.partialsData = []
            this.loadCounterAll = true

            var counterClaims = 0
            this.arrayFiltradoClaimsApproved.forEach(ele => {
                if(this.partialsData.filter(item => item.clinicianID === ele.clinicianID).length === 0) {
                    counterClaims += 1
                    if(counterClaims <= 50) {
                        this.partialsData.push(ele)
                    }
                }
            })

            setTimeout(() => {
                this.loadCounterAll = false
            }, 1000)
        },
        async queryClaims() {
            // 
        },
        checkActiveFilters() {
            if(this.clinicianName || this.clientName || this.claimStatus || this.clinicianRegion || this.clinicianBorough || this.clinicianStatus || this.startDate || this.endDate || this.clientID || this.clinicianProgram){
                this.activeFilters = true
                this.activeFiltersDate = false
                
                // !this.isCompleteData
                // if(this.clientName || this.clinicianName) {
                if(this.clientName || this.clinicianName || (this.startDate && this.endDate) || this.clinicianRegion || this.clinicianBorough || this.claimStatus || this.clinicianStatus || this.clinicianProgram) {
                    this.queryClaims()
                } 
                else if(!this.clientID) {
                // else if(!this.clinicianRegion && !this.clientID && !this.claimStatus && !this.clinicianStatus && !this.clinicianBorough && !this.startDate && !this.endDate) {
                    this.queryClaims()
                }
            } else {
                this.activeFilters = false
                this.activeFiltersDate = false
            }
        },
        BtnActivateClaim(val) {
            db.collection('clientsClaims').doc(val).update({
                status: 'active'
            })
            .then(() => {
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: `Claim activated`,
                        icon: 'CoffeeIcon',
                        variant: 'success'
                    },
                })
            })

            // snapshot clinician
            db.collection('clientsClaims').doc(val).get()
            .then(dataClientClaim => {
                if(dataClientClaim.data().clinician) {
                    db.collection('usuarios').doc(dataClientClaim.data().clinician).update({
                        updated: Date.now()
                    })
                }
            })
            // console.log(val)
        },
        BtnDeactivateClaim(val) {
            db.collection('clientsClaims').doc(val).update({
                status: 'inactive'
            })
            .then(() => {
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: `Claim diabled`,
                        icon: 'CoffeeIcon',
                        variant: 'success'
                    },
                })
            })

            // snapshot clinician
            db.collection('clientsClaims').doc(val).get()
            .then(dataClientClaim => {
                if(dataClientClaim.data().clinician) {
                    db.collection('usuarios').doc(dataClientClaim.data().clinician).update({
                        updated: Date.now()
                    })
                }
            })
            // console.log(val)
        },
        onRowSelected(items) {
            this.idClientClaim = items.id
            this.statusEdit = true
            // console.log(this.idClientActivity)
        },
        markAsPaidClaim(val) {
            if(val) {
                this.isBusy = true
                this.apiLoaded = true
                
                db.collection('clientsClaims').doc(val).update({
                    approvalStatus: 'paid',
                    lastApprovalStatus: 'approved',
                    datePaid: Date.now(),
                    paidBy: this.userUID
                })
                .then(() => {
                    // snapshot clinician
                    db.collection('clientsClaims').doc(val).get()
                    .then(dataClientClaim => {
                        if(dataClientClaim.data().clinician) {
                            db.collection('usuarios').doc(dataClientClaim.data().clinician).update({
                                updated: Date.now()
                            })
                        }
                    })

                    // this.clearSelected()
                    this.reloadData()
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: `Claims processed`,
                            icon: 'CoffeeIcon',
                            variant: 'success',
                        },
                    })
                })
                .catch(e => {
                    console.log(e)

                    this.isBusy = false
                    this.apiLoaded = false
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: `No claims to processed`,
                            icon: 'AlertCircleIcon',
                            variant: 'danger',
                        },
                    })
                })
            }
        },
        returnBackApprovalStatus(val) {
            if(val.id) {
                db.collection('clientsClaims').doc(val.id).update({
                    approvalStatus: val.last,
                    lastApprovalStatus: val.type,
                })
                .then(() => {
                    this.reloadData()
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: `Claim updated to ${val.last}`,
                            icon: 'CoffeeIcon',
                            variant: 'success',
                        },
                    })
                    // snapshot clinician
                    db.collection('clientsClaims').doc(val.id).get()
                    .then(dataClientClaim => {
                        if(dataClientClaim.data().clinician) {
                            db.collection('usuarios').doc(dataClientClaim.data().clinician).update({
                                updated: Date.now()
                            })
                        }
                    })
                })
                .catch(e => {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: `Error`,
                            icon: 'AlertCircleIcon',
                            variant: 'danger',
                        },
                    })
                    console.log(e)
                })
            }
        },
        markAsApproveClaim(val) {
            if(val.id) {
                db.collection('clientsClaims').doc(val.id).update({
                    approvalStatus: val.type,
                    lastApprovalStatus: val.act,
                })
                .then(() => {
                    this.reloadData()
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: `Claim updated to ${val.type}`,
                            icon: 'CoffeeIcon',
                            variant: 'success',
                        },
                    })
                    // snapshot clinician
                    db.collection('clientsClaims').doc(val.id).get()
                    .then(dataClientClaim => {
                        if(dataClientClaim.data().clinician) {
                            db.collection('usuarios').doc(dataClientClaim.data().clinician).update({
                                updated: Date.now()
                            })
                        }
                    })
                })
                .catch(e => {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: `Error`,
                            icon: 'AlertCircleIcon',
                            variant: 'danger',
                        },
                    })
                    console.log(e)
                })
            }
        },
        markAsDenyClaim(val) {
            if(val.id) {
                db.collection('clientsClaims').doc(val.id).update({
                    approvalStatus: val.type,
                    lastApprovalStatus: val.act,
                })
                .then(() => {
                    this.reloadData()
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: `Claim updated to ${val.type}`,
                            icon: 'CoffeeIcon',
                            variant: 'success',
                        },
                    })
                    // snapshot clinician
                    db.collection('clientsClaims').doc(val.id).get()
                    .then(dataClientClaim => {
                        if(dataClientClaim.data().clinician) {
                            db.collection('usuarios').doc(dataClientClaim.data().clinician).update({
                                updated: Date.now()
                            })
                        }
                    })
                })
                .catch(e => {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: `Error`,
                            icon: 'AlertCircleIcon',
                            variant: 'danger',
                        },
                    })
                    console.log(e)
                })
            }
        },
        processResumeClinician(val) {
            // console.log(val)
            if(val) {
                this.showOverlay = true
                this.isBusy = true
                this.apiLoaded = true
                this.isBusyResumeApproved = true
                this.latestDocQuery = null
                this.loadCounterQuery = true

                db.collection('usuarios').doc(val).get()
                .then(clinician => {
                    db.collection('clientsClaims').where('status', '==', 'active').where('clinician', '==', val).get()
                    .then(claimsClinician => {
                        var arrayApprove = []
                        var countClaim = 1
                        var isZeroClaim = false
                        var amountBilledToCFEQuick = 0
                        claimsClinician.forEach(doc => {
                            if(doc.data().approvalStatus === 'approved') {
                                // claimsClinician.forEach(docCl => {
                                    db.collection('clientsClaims').doc(doc.id).update({
                                        approvalStatus: 'paid',
                                        lastApprovalStatus: doc.data().approvalStatus,
                                        datePaid: Date.now(),
                                        paidBy: this.userUID
                                    })

                                    // snapshot clinician
                                    db.collection('clientsClaims').doc(doc.id).get()
                                    .then(dataClientClaim => {
                                        if(dataClientClaim.data().clinician) {
                                            db.collection('usuarios').doc(dataClientClaim.data().clinician).update({
                                                updated: Date.now()
                                            })
                                        }
                                    })
                                // })

                                // this.showOverlay = true
                                // this.isBusy = true
                                // this.apiLoaded = true
                                // this.isBusyResumeApproved = true
                                // var validateFixed = doc.data().amountBilledToCFE.includes('.') ? parseFloat(doc.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(doc.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                // var amountBilledToCFEParcial = doc.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))
                                // amountBilledToCFEQuick += doc.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                // if(amountBilledToCFEQuick >= 1) {
                                //     isZeroClaim = true
                                // }

                                // var objAppr = {
                                //     "DetailType": "AccountBasedExpenseLineDetail", 
                                //     "Amount": amountBilledToCFEParcial,
                                //     "Id": countClaim.toString(), 
                                //     "AccountBasedExpenseLineDetail": {
                                //         "AccountRef": {
                                //             "value": "94"
                                //         },
                                //         "ClassRef": {
                                //             "value": "5000000000000226817"                   
                                //         }
                                //     }
                                // }

                                // arrayApprove.push(objAppr)
                                // countClaim += 1
                            }
                        })

                        this.$toast({
                            component: ToastificationContent,
                            position: 'top-right',
                            props: {
                                title: `Claims processed successfully`,
                                icon: 'CoffeeIcon',
                                variant: 'success'
                            },
                        })
                        this.reloadData()
                        this.showOverlay = false
                    })
                })
            }
        },
        reloadData() {
            this.isBusyResumeApproved = true
            this.isCompleteData = false
            this.loadCounterAll = true
            this.apiLoaded = true
            this.loadCounterQuery = true
            this.isBusy = true
            this.itemsClaims = []
            this.latestDoc = null
            this.latestDocQuery = null
            this.getClaimsLocal()
            this.itemsClaimsQuery = []
            this.queryClaims()

            setTimeout(() => {
                this.$http.get('https://us-central1-nycpg-67e48.cloudfunctions.net/app/api/prod/approved/claims')
                .then(res => {
                    this.isBusyResumeApproved = false
                    // this.cliniciansResume = res.data
                    this.loadCounterAll = false
                    this.setSnapshotClaimsApproved(res.data)
                })

                // this.$http.get('https://us-central1-nycpg-67e48.cloudfunctions.net/app/api/prod/active/claims')
                // .then(res => {
                //     this.isCompleteData = true
                //     this.apiLoaded = true
                //     this.isBusy = false

                //     this.setSnapshotClaimsApproved(res.data)
                // })
            }, 3000)
        },
        async getClaimsLocal() {
            // // db.collection
            // // if(this.itemsClaims !== 0) {
            // // const ref = db.collection('clientsClaims').where('status', '>=', 'active').orderBy('status').startAfter(this.latestDoc || 0).limit(25)
            // const ref = db.collection("clientsClaims").where('status', '>=', 'active').orderBy('status').orderBy("claimDate", "desc").startAfter(this.latestDoc || 0).limit(50)
            // const data = await ref.get()

            // // ouputs docs
            // // .then(docsClients => {
            // data.docs.forEach(dataClientClaim => {
            //     // console.log(dataClientClaim.data().status)
            //     var obj = {
            //         id: dataClientClaim.id,
            //         status: dataClientClaim.data().status,
            //         clientID: '#' + dataClientClaim.data().clientID,
            //         clientName: dataClientClaim.data().clientName,
            //         clinicianID: '#' + dataClientClaim.data().clinicianID,
            //         clinicianName: dataClientClaim.data().clinicianName,
            //         approvalStatus: dataClientClaim.data().approvalStatus,
            //         lastApprovalStatus: dataClientClaim.data().lastApprovalStatus ? dataClientClaim.data().lastApprovalStatus : dataClientClaim.data().approvalStatus === 'paid' ? 'approved' : '',
            //         claimDate: dataClientClaim.data().claimDateMonth + ' ' + dataClientClaim.data().claimDateYear,
            //         claimDateFormat2: dataClientClaim.data().claimDateYear + '/' + moment().month(dataClientClaim.data().claimDateMonth).format("MM") + '/01 00:00:00',
            //         dateFormatOrder: new Date(dataClientClaim.data().claimDateYear + '-' +  moment().month(dataClientClaim.data().claimDateMonth).format("MM") + '/01'),
            //         region: dataClientClaim.data().region,
            //         regionBorough: dataClientClaim.data().regionBorough ? dataClientClaim.data().regionBorough : '',
            //         clinicianStatus: dataClientClaim.data().statusClinician,
            //         clinicianProgram: dataClientClaim.data().clinicianProgram ? dataClientClaim.data().clinicianProgram : ''
            //     }
            //     this.itemsClaims.push(obj)
            // })
            // // console.log(this.itemsClaims.length)
            // this.itemsClaims.sort((a, b) => b.dateFormatOrder - a.dateFormatOrder)
            // this.latestDoc = data.docs[data.docs.length - 1]
            // this.loadingInfiniteScroll = false
            // this.isBusy = false
            // if(data.empty) {
            //     window.removeEventListener('scroll', this.handleScroll)
            // }
        },
        handleScroll(event) {
        },
        loadMoreClaims() {
            if(!this.activeFilters) {
                this.getClaimsLocal()
            }
            this.loadingInfiniteScroll = true
            this.perPage += 10
        },
        loadMoreClaimsFilters() {
            if(this.activeFilters) {
                this.queryClaims()
            }
            this.loadingInfiniteScroll = true
            this.perPage += 10
        }
    },
    destroyed() {
        window.removeEventListener('scroll', this.handleScroll)
    },
    mounted() {
        this.activeFiltersDate = false
        this.itemsClaims = []
        this.cliniciansResume = []
        // setInterval(function() {
        // this.getClaimsLocal()
        // }, 1000)

        this.optionsClientName = []
        this.optionsClinicianName = []
        this.$http.get('https://us-central1-nycpg-67e48.cloudfunctions.net/app/api/filter/clinicians')
        .then(res => {
            this.optionsClinicianName = [{id: 0, uid: 0, value: 'All Clinicians', full: 'All Clinicians'}]
            res.data.forEach(ele => {
                this.optionsClinicianName.push(ele)
            })
        })

        // this.$http.get('https://us-central1-nycpg-67e48.cloudfunctions.net/app/api/filter/clients')
        // .then(res => { 
        //     this.optionsClientName = [{id: 0, uid: 0, value: 'All Clients'}]
        //     res.data.forEach(ele => {
        //         if(this.clientName) {
        //             var filterName = ele.value ? ele.value.toLowerCase() : ''
        //             if(filterName && filterName === this.clientName) {
        //                 this.clientName = ele
        //             }
        //         }
        //         this.optionsClientName.push(ele)
        //     })
        // })

        // this.optionsClientName = [{value: null, text: 'Client Name'}]
        // this.optionsClinicianName = [{value: null, text: 'Clinician Name'}]
        // this.getUsers()

        window.addEventListener('scroll', this.handleScroll)
    },
    created() {
        this.json_data = []
        this.partialsData = []
        this.loadCounterAll = true
        this.clinicianProgram = null

        var getRegionState = null
        var getBoroughState = null
        if(this.searchClaimApprovedClinicianRegionState) {
            if(this.searchClaimApprovedClinicianRegionState === 'nyc') {
                getRegionState = 'NYC'
            } else if(this.searchClaimApprovedClinicianRegionState === 'central') {
                getRegionState = 'Central'
            } else if(this.searchClaimApprovedClinicianRegionState === 'finger lakes') {
                getRegionState = 'Finger Lakes'
            } else if(this.searchClaimApprovedClinicianRegionState === 'long island') {
                getRegionState = 'Long Island'
            } else if(this.searchClaimApprovedClinicianRegionState === 'mid-hudson') {
                getRegionState = 'Mid-Hudson'
            } else if(this.searchClaimApprovedClinicianRegionState === 'northeast') {
                getRegionState = 'Northeast'
            } else if(this.searchClaimApprovedClinicianRegionState === 'western') {
                getRegionState = 'Western'
            } else if(this.searchClaimApprovedClinicianRegionState === 'bronx') {
                getBoroughState = 'Bronx'
                getRegionState = 'NYC'
            } else if(this.searchClaimApprovedClinicianRegionState === 'brooklyn') {
                getBoroughState = 'brooklyn'
                getRegionState = 'NYC'
            } else if(this.searchClaimApprovedClinicianRegionState === 'manhattan') {
                getBoroughState = 'manhattan'
                getRegionState = 'NYC'
            } else if(this.searchClaimApprovedClinicianRegionState === 'queens') {
                getBoroughState = 'Queens'
                getRegionState = 'NYC'
            } else if(this.searchClaimApprovedClinicianRegionState === 'staten island') {
                getBoroughState = 'Staten Island'
                getRegionState = 'NYC'
            }
        }

        // this.clinicianName = this.searchClaimApprovedClinicianNameState ? this.searchClaimApprovedClinicianNameState : null
        // this.clientName = this.searchClaimNameState ? this.searchClaimNameState : null
        // this.claimStatus = this.searchClaimStatusState ? this.searchClaimStatusState : null
        // // this.clinicianRegion = this.searchClaimApprovedClinicianRegionState ? this.searchClaimApprovedClinicianRegionState : null
        // this.clinicianRegion = getRegionState
        // this.clinicianBorough = getBoroughState
        // this.clinicianStatus = this.searchClaimClinicianStatusState ? this.searchClaimClinicianStatusState.replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : null
        // this.startDate = this.searchClaimStartDateState ? this.searchClaimStartDateState : null
        // this.endDate = this.searchClaimEndDateState ? this.searchClaimEndDateState : null
        // this.clientID = this.searchClaimIDState ? this.searchClaimIDState : null
        // this.clinicianProgram = this.searchClaimApprovedClinicianProgramState ?  this.searchClaimApprovedClinicianProgramState : null

        this.clinicianName = null
        this.clientName = null
        this.claimStatus = null
        this.clinicianRegion = null
        this.clinicianBorough = null
        this.clinicianStatus = null
        this.startDate = null
        this.endDate = null
        this.clientID = null
        this.clinicianProgram = null

        this.userUID = ''
        const user = auth.currentUser
        if(user) {
            this.userUID = user.uid
            db.collection('usuarios').doc(user.uid).get()
            .then(userDoc => {
                if(userDoc.data().role === 'admin') {
                    this.adminOrManageUsers = true
                    this.isAdmin = true
                } else if(userDoc.data().role === 'staff') {
                    if(userDoc.data().permissions.includes('manage users')) {
                        this.adminOrManageUsers = true
                    } else if(userDoc.data().permissions.includes('data specialist')) {
                        this.dataSpecialist = true
                    } else if(userDoc.data().permissions.includes('clinician viewer')) {
                        this.clinicianOrClinicianViewer = true
                    } else {
                        this.clinicianOrClinicianViewer = true
                    }
                } else if(userDoc.data().role === 'clinician') {
                    this.isClinician = true
                }
            })
        }

        // get active claims
        // this.$http.get('https://us-central1-nycpg-67e48.cloudfunctions.net/app/api/prod/active/claims')
        // .then(res => {
        //     this.isCompleteData = true
        //     this.apiLoaded = true
        //     this.isBusy = false

        //     this.setSnapshotClaimsApproved(res.data)
        // })

        this.$http.get('https://us-central1-nycpg-67e48.cloudfunctions.net/app/api/prod/approved/claims')
        .then(res => {
            // this.apiLoaded = true
            this.isBusyResumeApproved = false
            this.loadCounterAll = false
            // console.log(res.data)
            // this.cliniciansResume = res.data
            this.setSnapshotClaimsApproved(res.data)
        })

        // var body = {
        //     type: 'all'
        // }

        // var config = {
        //     'url': 'https://us-central1-nycpg-67e48.cloudfunctions.net/allCounters/api/counters/all-claims',
        //     'method': 'POST',
        //     'timeout': 0,
        //     'headers': {
        //         'Content-Type': 'application/json',
        //         'Accept': 'application/json'
        //     },
        //     'data': JSON.stringify(body),
        // }

        // this.axios(config)
        // .then(res => {
        //     // console.log(res.data)
        //     this.totalRows = res.data
        //     this.loadCounterAll = false

        //     db.collection('clientsClaims').doc('counter').update({
        //         totalClaims: res.data
        //     })
        // })
        // .catch(e => console.log(e.message))

        // db.collection('clientsClaims').doc('counter').get()
        // .then(counter => {
        //     this.totalRows = counter.data().totalClaims
        //     this.loadCounterAll = false
        //     // this.isBusy = false
        // })

        db.collection('taxonomies').doc('clinician').get()
        .then(docTaxonomia => {
            this.optionsClinicianRegion = [{value: null, text: 'Any Region'}]
            docTaxonomia.data().region.forEach(item => {
                this.optionsClinicianRegion.push({value: item, text: item})
            })

            var arrayProgram = [{value: null, text: 'Any Program'}]
            docTaxonomia.data().program.forEach(item => {
                arrayProgram.push({value: item, text: item})
            })
            this.optionsProgram = arrayProgram
        })

        this.searchClaimApprovedClinicianName('')
        // this.searchClaimClientName('')
        // this.searchClaimClaimStatus('')
        this.searchClaimApprovedClinicianRegion('')
        // this.searchClaimClinicianStatus('')
        // this.searchClaimStartDate('')
        // this.searchClaimEndDate('')
        // this.searchClaimsClientID('')
        this.searchClaimApprovedClinicianProgram('')
    }
}
</script>

<style>
.thClaimsActions {
    padding: 0px !important;
}
.tdClaimsActions {
    width: 180px !important;
    padding: 0px !important;
}
.tdClinicianResumeName {
    /* white-space: nowrap; */
    /* width: 100px !important; */
    padding: 10px !important;
}
.thClinicianNoClaims {
    white-space: nowrap;
    padding: 10px !important;
}
.tdClinicianNoClaims {
    padding: 10px !important;
    /* width: 50px !important; */
}
.thClaimsApprovedTotal {
    padding-right: 3px !important;
    padding-left: 3px !important;
}
.tdClaimsApprovedTotal {
    padding-right: 3px !important;
    padding-left: 3px !important;
}
.tdClaimsApprovedActions {
    padding: 0px !important;
}
.vs__dropdown-option {
  white-space: normal !important;
}
.vs__dropdown-toggle {
  max-height: 38px;
}
.vs__selected-options {
  overflow: auto;
}
.vs__selected-options {
    overflow-x: hidden;
    overflow-y: hidden;
}
/* ::placeholder {
  color: #000 !important;
  opacity: 1;
}

:-ms-input-placeholder {
 color: #000 !important;
}

::-ms-input-placeholder {
 color: #000 !important;
} */
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
</style>